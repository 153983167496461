import { TRACKING_API_RESPONSE_ERROR, TRACKING_API_RESPONSE_SUCCESS, TRACKING_CHECK_SHIPMENT, TRACKING_CLEAR_CACHE } from "./actionType";

// common success
export const trackingApiResponseSuccess = (actionType, data) => ({
	type: TRACKING_API_RESPONSE_SUCCESS,
	payload: { actionType, data },
});
// common error
export const trackingApiResponseError = (actionType, error) => ({
	type: TRACKING_API_RESPONSE_ERROR,
	payload: { actionType, error },
});

export const trackingCheckShipment = (data) => ({
	type: TRACKING_CHECK_SHIPMENT,
	payload: data,
});
//Demension
export const trackingClearCache = () => ({
	type: TRACKING_CLEAR_CACHE,
});
