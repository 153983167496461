import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Mailbox Redux States
import { TRACKING_CHECK_SHIPMENT } from "./actionType";

//Include Both Helper File with needed methods
import { checkShipment } from "../../helpers/fakebackend_helper";
import { trackingApiResponseSuccess, trackingApiResponseError } from "./action";

//GET LIST HISTORY
function* trackingCheckShipment({ payload: data }) {
	try {
		const response = yield call(checkShipment, data);
		yield put(trackingApiResponseSuccess(TRACKING_CHECK_SHIPMENT, response));
	} catch (error) {
		yield put(trackingApiResponseError(TRACKING_CHECK_SHIPMENT, error));
	}
}
export function* watchTrackingCheckShipment() {
	yield takeEvery(TRACKING_CHECK_SHIPMENT, trackingCheckShipment);
}

function* DashboardTrackingSaga() {
	yield all([fork(watchTrackingCheckShipment)]);
}

export default DashboardTrackingSaga;
