import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import {
	Col,
	Container,
	Row,
	Card,
	CardHeader,
	CardBody,
	Label,
	Button,
	Badge,
	Input,
	Spinner,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	FormFeedback,
	Nav,
	NavItem,
	TabContent,
	TabPane,
	NavLink,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import LabelComponent from "../../Components/Hooks/LabelComponent";
import { useFormik, Formik, useFormikContext } from "formik";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTableComponent from "../../Components/Hooks/DataTableComponent";
import SelectTreeComponent from "../../Components/Hooks/SelectTreeComponent";

import { trackingCheckShipment, trackingClearCache } from "../../store/actions";
import SelectFilterComponent from "../../Components/Hooks/SelectFilterComponent";
import SelectComponent from "../../Components/Hooks/SelectComponent";
import useAuthUser from "../../Components/Hooks/AuthUser";
import InputComponent from "../../Components/Hooks/InputComponent";
import ModalLoading from "../../Components/Hooks/ModalLoading";
import FormSubmit from "../../Components/Common/FormSubmit";
import moment from "moment";

function Tracking() {
	document.title = "Tracking | IP Supply";
	const dispatch = useDispatch();
	const { arrayPageRoles } = useAuthUser();
	const { search, pathname } = useLocation();

	const { dataCheckShipment } = useSelector((state) => {
		return {
			dataCheckShipment: state.DashboardTracking.dataCheckShipment,
		};
	});

	const [currentPage, setCurrentPage] = useState(1);

	const [optionShipment, setOptionShipment] = useState([]);
	const [listShipments, setListShipments] = useState([
		{ id: "FEDEX", code: "Fedex" },
		{ id: "TNT", code: "TNT" },
		{ id: "UPS", code: "UPS" },
		{ id: "AUSPOST", code: "Auspost" },
		{ id: "SENDLE", code: "Sendle" },
		{ id: "USPS", code: "USPS" },
		{ id: "DHL", code: "DHL" },
		{ id: "STARTRACK", code: "StarTrack" },
		{ id: "COURIERSPLEASE", code: "CouriersPlease" },
	]);
	const [inputSearchShipment, setInputSearchShipment] = useState("");
	const [inputTrackingNumber, setInputTrackingNumber] = useState("");
	const [error, setError] = useState([]);
	const [data, setData] = useState([]);
	const [isTracking, setIsTracking] = useState(false);
	const [checkingError, setCheckingError] = useState(null);
	const [isDelivered, setIsDelivered] = useState(false);
	const [params, setParams] = useState({
		name: new URLSearchParams(search).get("name"),
		num: new URLSearchParams(search).get("num"),
	});

	const handleTracking = () => {
		let isError = false;
		if (inputTrackingNumber === "") {
			setError((pre) => [...new Set([...pre, "tracking"])]);
			isError = true;
		}
		if (!optionShipment?.id) {
			setError((pre) => [...new Set([...pre, "shipment"])]);
			isError = true;
		}
		if (!isError) {
			setData([]);
			setCheckingError(null);
			setIsTracking(true);
			dispatch(trackingClearCache());
			dispatch(trackingCheckShipment([{ trackingNumber: inputTrackingNumber?.trim(), courierName: optionShipment?.id }]));
		}
	};

	useEffect(() => {
		let isError = false;
		if (params?.name) {
			const shipment = listShipments?.find((value) => value?.id === params.name);
			if (shipment?.id) setOptionShipment(shipment);
			else {
				setError((pre) => [...new Set([...pre, "shipment"])]);
				isError = true;
			}
		} else isError = true;

		if (params?.num) {
			setInputTrackingNumber(params?.num);
		} else isError = true;

		if (!isError) {
			setData([]);
			setCheckingError(null);
			setIsTracking(true);
			dispatch(trackingClearCache());
			dispatch(trackingCheckShipment([{ trackingNumber: params?.num, courierName: params?.name }]));
		}
	}, [params, listShipments]);

	useEffect(() => {
		if (dataCheckShipment) {
			if (dataCheckShipment?.events && dataCheckShipment?.events?.length > 0) {
				setIsTracking(false);
				setData(dataCheckShipment?.events);
				setCheckingError(null);
				if (dataCheckShipment?.delivered) setIsDelivered(true);
				else setIsDelivered(false);
			} else {
				if (dataCheckShipment?.errorMessage) setCheckingError(dataCheckShipment?.errorMessage);
				setIsTracking(false);
				setData([]);
			}
		}
	}, [dataCheckShipment]);

	return (
		<React.Fragment>
			<ToastContainer closeButton={false} />
			<Container fluid>
				<Card className="mb-0" style={{ height: "100vh" }}>
					<Row className="p-3 align-items-center justify-content-center">
						<div style={{ width: "250px" }} className="fs-1 fw-bold">
							Tracking Page
						</div>
					</Row>
					<Row className="p-3 pb-0">
						<Col xs={12}>
							<Row className="align-items-center justify-content-center">
								<div style={{ width: "400px", height: "60px" }}>
									<InputComponent
										type="search"
										value={inputTrackingNumber}
										onChange={(value) => {
											setInputTrackingNumber(value);
											if (value !== "") setError((pre) => pre?.filter((e) => e !== "tracking"));
										}}
										placeholder="Input Tracking Number"
										invalid={error?.includes("tracking")}
										error="Please enter a tracking number"
									/>
								</div>
								<div style={{ width: "300px", height: "60px" }}>
									<SelectComponent
										options={listShipments}
										name="shipment"
										onChange={(option) => {
											setOptionShipment(option);
											setInputSearchShipment("");
											if (option?.id) setError((pre) => pre?.filter((e) => e !== "shipment"));
										}}
										value={optionShipment?.id ? optionShipment : []}
										placeholder={"Select shipment"}
										fieldValue="id"
										setInputValue={setInputSearchShipment}
										inputValue={inputSearchShipment}
										display="code"
										requiredField
										error={"Please select shipment"}
										touched={error?.includes("shipment")}
									/>
								</div>
								<div style={{ width: "100px", height: "60px" }}>
									<Button type="button" color="primary" onClick={handleTracking}>
										Check
									</Button>
								</div>
							</Row>
						</Col>
					</Row>
					{isTracking && (
						<Row className="p-3 align-items-center justify-content-center">
							<Spinner color="success"></Spinner>
						</Row>
					)}
					{data?.length > 0 && (
						<div>
							<div className="d-flex align-items-center justify-content-center fs-3 fw-bold">Shipping Information</div>
							<div
								className="mt-3 pt-4"
								style={{
									height: window.innerWidth >= 1600 ? "75vh" : "70vh",
									overflowY: "auto",
									borderTop: "1px #ccc solid",
									borderBottom: "1px #ccc solid",
								}}>
								<Row className="m-0">
									<Col xs={4}></Col>
									<Col xs={8}>
										<div className="timeline-2">
											<div className="timeline-continue">
												{data?.map((value, index) => (
													<Row key={index} className="timeline-right me-0">
														<Col xs={12}>
															<div className="timeline-date">
																<div className="timeline-box">
																	<div className="timeline-text">
																		{value?.eventDescription && <h5 className="fs-15">{value?.eventDescription}</h5>}
																		{value?.address && <span className="fs-6 mb-3 text-success">{value?.address}</span>}
																		<p className="text-primary mb-0">{moment(value?.time, "YYYY-MM-DD").format("YYYY-MM-DD")}</p>
																	</div>
																</div>
															</div>
															{/* <p className="timeline-date">{moment(value?.time, "YYYY-MM-DD").format("YYYY-MM-DD")}</p> */}
														</Col>
													</Row>
												))}
												{!isDelivered && (
													<Row className="timeline-right me-0">
														<Col xs={12}>
															<div className="timeline-date-muted">
																<div className="timeline-box">
																	<div className="timeline-text">
																		<h5 className="fs-15 text-muted">Delivered</h5>
																	</div>
																</div>
															</div>
															{/* <p className="timeline-date">{moment(value?.time, "YYYY-MM-DD").format("YYYY-MM-DD")}</p> */}
														</Col>
													</Row>
												)}
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					)}
					{checkingError && (
						<div>
							<div className="d-flex align-items-center justify-content-center fs-3 fw-bold">Shipping Information</div>
							<div className="d-flex align-items-center justify-content-center fs-5 mt-4">{checkingError}</div>
						</div>
					)}
				</Card>
			</Container>
		</React.Fragment>
	);
}

export default Tracking;
