import { TRACKING_API_RESPONSE_ERROR, TRACKING_API_RESPONSE_SUCCESS, TRACKING_CHECK_SHIPMENT, TRACKING_CLEAR_CACHE } from "./actionType";

const INIT_STATE = {
	error: {},
	dataCheckShipment: null,
};

const DashboardTracking = (state = INIT_STATE, action) => {
	switch (action.type) {
		case TRACKING_API_RESPONSE_SUCCESS:
			switch (action.payload.actionType) {
				case TRACKING_CHECK_SHIPMENT:
					return {
						...state,
						dataCheckShipment: action.payload.data[0],
					};

				default:
					return { ...state };
			}
		case TRACKING_API_RESPONSE_ERROR:
			switch (action.payload.actionType) {
				case TRACKING_CHECK_SHIPMENT:
					return {
						...state,
						error: action.payload.error,
					};
				default:
					return { ...state };
			}
		case TRACKING_CLEAR_CACHE:
			return {
				...state,
				dataCheckShipment: null,
			};

		default:
			return { ...state };
	}
};

export default DashboardTracking;
