import React, { useEffect, useState } from "react";
import { TreeSelect } from "antd";
import { Col, Row } from "reactstrap";

function SelectTreeComponent({
	keyField,
	display,
	childrenFieldLevel2,
	childrenFieldLevel3,
	disabled,
	className,
	value,
	onChange,
	onSelect,
	options,
	placeholder,
	multiple,
	onKeyDown,
	treeDefaultExpandAll,
	onSearch,
	searchValue,
	treeLine,
	label,
	style
}) {
	//Link docs: https://ant.design/components/tree-select
	//Data mẫu
	// const treeData = [
	//     {
	//       value: 'parent 1',
	//       title: 'parent 1',
	//       children: [
	//         {
	//           value: 'parent 1-0',
	//           title: 'parent 1-0',
	//           children: [
	//             {
	//               value: 'leaf1',
	//               title: 'leaf1',
	//             },
	//           ],
	//         },
	//       ],
	//     },
	//   ];

	const initTreeLine = typeof treeLine !== "undefined" ? treeLine : true;
	const initTreeDefaultExpandAll = typeof treeDefaultExpandAll !== "undefined" ? treeDefaultExpandAll : true;

	//Xu ly lại Data
	const listData = () => {
		const arrOptions = options?.map((value) => {
			value.value = value[keyField];
			value.title = value[display];
			value.children = value[childrenFieldLevel2]?.map((valueLevel2) => {
				valueLevel2.value = valueLevel2[keyField];
				valueLevel2.title = valueLevel2[display];
				if (childrenFieldLevel3) {
					valueLevel2.children = valueLevel2[childrenFieldLevel3]?.map((valueLevel3) => {
						valueLevel3.value = valueLevel3[keyField];
						valueLevel3.title = valueLevel3[display];
						return valueLevel3;
					});
				}
				return valueLevel2;
			});
			return value;
		});
		return arrOptions;
	};

	return (
		// <div className="d-flex align-items-center gap-2">
		// 	{label ? <div className="block text-xaplink_gray_3 text-sm font-bold flex-shrink-0">{label}</div> : null}
		// 	<TreeSelect
		// 		allowClear
		// 		showSearch
		// 		onKeyDown={onKeyDown}
		// 		disabled={disabled}
		// 		className={typeof className !== "undefined" ? className : "w-100"}
		// 		value={value}
		// 		onChange={onChange}
		// 		treeData={listData()}
		// 		placeholder={placeholder}
		// 		multiple={multiple}
		// 		dropdownStyle={{
		// 			maxHeight: 400,
		// 			overflow: "auto",
		// 		}}
		// 		treeDefaultExpandAll={initTreeDefaultExpandAll}
		// 		onSearch={onSearch}
		// 		searchValue={searchValue}
		// 		treeNodeFilterProp="title"
		// 		treeLine={initTreeLine}
		// 	/>
		// </div>

		<Row>
			{label ? (
				<Col md={12} lg={12} xl={12} xxl={12} style={{ whiteSpace: "nowrap" }}>
					{label}
				</Col>
			) : null}
			{/* <Col style={{ marginTop: label ? "unset" : "38px" }} className="align-self-center"> */}
			<Col className="align-self-center" style={typeof style !== "undefined" ? style : {}}>
				<TreeSelect
					allowClear
					showSearch
					onKeyDown={onKeyDown}
					disabled={disabled}
					className={typeof className !== "undefined" ? className : "w-100"}
					value={value}
					onChange={onChange}
					onSelect={onSelect}
					treeData={listData()}
					placeholder={placeholder}
					multiple={multiple}
					dropdownStyle={{
						maxHeight: 400,
						overflow: "auto",
					}}
					treeDefaultExpandAll={initTreeDefaultExpandAll}
					onSearch={onSearch}
					searchValue={searchValue}
					treeNodeFilterProp="title"
					treeLine={initTreeLine}
					// getPopupContainer={trigger => trigger.parentElement}
				/>
			</Col>
		</Row>
	);
}

export default SelectTreeComponent;
